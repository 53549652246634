<!--8个人中心-14团队成员-4经纪人-->
<template>
  <div class="team_page">
    <div class="team_main">
      <div class="agree_pos">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/personage/team' }">区域总监</el-breadcrumb-item>
          <el-breadcrumb-item>{{ from.city }}店长</el-breadcrumb-item>
          <el-breadcrumb-item>{{ from.dian_name }}</el-breadcrumb-item>
          <el-breadcrumb-item>经理</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      
      <!--team_list start-->
      <div class="team_list">
        <!--team_item start-->
        <div class="team_item" v-for="(item, index) in shopList" :key="index">
          <div class="team_box">
            <div class="team_links">
              <router-link
                class="color_blue links-flex"
                :to="`/personage/teamManager?manager_id=${item.job_number}&city=${from.city}&name=${item.name}`"
              >
                <img
                  class="links-icon"
                  src="../../assets/image/personage/team1.png"
                />
                <span>查看下级</span>
              </router-link>
            </div>
            <div class="team_hd">
              <div class="hd_img">
               <img :src="item.image" />
              </div>
              <div class="hd_txt">
                <div class="name">{{ item.name }}</div>
                <div class="flex-c">
                  <div class="flex">
                    <img
                      class="icon"
                      src="../../assets/image/personage/team2.png"
                    />
                    <span class="cc">{{ item.job_number }}</span>
                  </div>
                  <div class="flex">
                    <img
                      class="icon"
                      src="../../assets/image/personage/team3.png"
                    />
                    <span class="cc">{{ item.mobile }}</span>
                  </div>
                  <div class="flex">
                    <img
                      class="icon"
                      src="../../assets/image/personage/team4.png"
                    />
                    <span class="cc">{{ item.dian_name }}</span>
                  </div>
                  <div class="flex">
                    <el-tooltip
                        :content="item.fang_type_privilege === 1 ? '该经理为写字楼权限' : '该经理为非写字楼权限'"
                        placement="top">
                      <el-switch
                          v-model="item.fang_type_privilege"
                          :active-value="1"
                          :inactive-value="0"
                          active-color="#ff4949"
                          inactive-color="#13ce66"
                          @change="changeBrokerFangTypePrivilege(item.job_number,item.fang_type_privilege)">
                      </el-switch>
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </div>
            <div class="team_bd">
              <div class="li">
                <div class="li-con"><span class="color-6">维护方:</span> {{ item.weihu_count }}</div>
              </div>
              <div class="li">
                <div class="li-con"><span class="color-6">信息方:</span> {{ item.xinxi_count }}</div>
              </div>
              <div class="li">
                <div class="li-con">
                  <span class="color-6">考勤记录:</span> {{ item.kaoqin }}
                </div>
              </div>
              <div class="li">
                <div class="li-con"><span class="color-6">图片方:</span> {{ item.image_count }}</div>
              </div>
              <div class="li">
                <div class="li-con"><span class="color-6">委托方:</span> {{ item.weituo_count }}</div>
              </div>
              <div class="li">
                <div class="li-con">
                  <span class="color-6">考核记录:</span> {{ item.examine_records }}
                </div>
              </div>
              <div class="li">
                <div class="li-con"><span class="color-6">钥匙方:</span> {{ item.keys_count }}</div>
              </div>
              <div class="li">
                <div class="li-con">
                  <span class="color-6">总业绩:</span> {{ item.total_performance }}
                </div>
              </div>
              <div class="li">
                <div class="li-con">
                  <span class="color-6">私客数量:</span> {{ item.client_count }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--team_item end-->
      </div>
      <!--team_list end-->
      <div class="paging" v-if="shopList.length">
        <el-pagination
          background
          @current-change="changePage"
          layout="prev, pager, next, jumper, ->, total"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Team",
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(["userInfo"]),
  },
  data() {
    return {
      total: 0,
      shopList: [],
      from:{
        page:1,
        dian_id:null,
        city:'',
        dian_name:''
      }
    };
  },
  methods: {
    //分页
    changePage (e) {
      this.from.page = e
      this.getShopList()
    },
    //获取店长列表
    getShopList() {
      this.$axios
        .nextManagerList(this.from)
        .then((res) => {
          this.shopList = res.data.data;
          this.total = res.data.total;
        });
    },
    changeBrokerFangTypePrivilege(jobNum,fang_type_privilege) {
      let data = {
        jobNum: jobNum,
        fang_type_privilege: fang_type_privilege
      }
      this.$axios.changeBrokerFangTypePrivilege(data).then((res) => {
        this.$message({message: res.msg,type: 'success'});
      });
    }
  },
  created() {
    this.from.dian_id = this.$route.query.dian_id;
    this.from.city = this.$route.query.city;
    this.from.dian_name = this.$route.query.dian_name;
    this.getShopList();
  },
};
</script>

<style scoped lang="less">
.agree_pos {
  display: flex;
  padding: 15px 30px;
  color: #666;
}
.links-flex {
  display: flex;
  align-items: center;
}
.links-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.team_main {
  background: #fff;
  padding-bottom: 30px;
  padding-top: 15px;
}
.team_title {
  padding: 15px 30px 15px 40px;
  font-size: 18px;
  position: relative;
}
.team_title:before {
  display: block;
  left: 30px;
  top: 50%;
  margin-top: -10px;
  background: #3273f6;
  width: 4px;
  height: 20px;
  border-radius: 2px;
  position: absolute;
  content: "";
}
.team_list {
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px;
  .team_item {
    width: 50%;
  }
  .team_box {
    margin: 10px 20px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0px 0px 6px 0px rgba(189, 189, 189, 0.16);
    padding: 20px;
    position: relative;
  }
  .team_links {
    position: absolute;
    right: 20px;
    top: 15px;
  }
  .team_hd {
    display: flex;
    .hd_img {
      width: 56px;
      min-width: 56px;
      max-width: 56px;
    }
    .hd_img img {
      width: 56px;
      height: 56px;
      border-radius: 100%;
    }
    .hd_txt {
      width: 100%;
      .name {
        font-size: 18px;
        margin-left: 20px;
      }
      .c {
        color: #666;
      }
      .icon {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
      .flex-c {
        display: flex;
        font-size: 14px;
        color: #666;
        margin: 10px 0px;
        flex-wrap: wrap;
        width: 100%;
        margin-left: 20px;
        .flex {
          margin: 4px 0px;
          justify-content: flex-start;
          min-width: 40%;
          align-items: center;
        }
      }
    }
  }
  .team_bd {
    border-top: 1px solid #ebebeb;
    margin: 0 -5px;
    padding-top: 10px;
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;
    .li {
      width: 33.33333%;
    }
    .li-con {
      margin: 5px;
      color: #ff752a;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
/deep/ .el-breadcrumb__item:last-child {
  .el-breadcrumb__inner {
    color: #3273F6;
  }
    
  }
</style>
